import { appConfigurationProd } from './app-configuration/app-configuration';
import process from 'process';

export const DSL_BASE_URL = process.env.REACT_APP_DSL_BASE_URL;

export const DSL_API_PATH = {
    VEHICLE_IMAGE: '/vehicleimage/api/v1/vehicles/image/full',
    VEHICLE_IMAGE_V2: '/vehicleimage/api/v2/vehicles/image/full',
    RECALLS_VIN: '/gcamp/api/v5/recalls',
    CAMPAIGN_ID: '/owner/api/v2/campaign',
    COMMUNICATION_PREFERENCES_IMG: '/fs/api/v2/privacy/img-preference-centre',
    RECALLS_YMM: '/owner/api/v2/recalls/model-year',
    PROFILE_WITH_VEHICLES: '/fs/api/v2/profile',
    PROFILE_WITH_VEHICLES_LITE: '/fs/api/v2/profile/lite',
    SWAP_TOKEN: '/fs/api/v2/profile/token/swap',
    ADD_VEHICLE: '/fs/api/v3/vehicles',
    FORD_RIDES_TRIPS: '/trips/api/v1',
    FPR_URL_VALIDATION: '/wsmod/api/v1/validate-fpr-url',
    SYNC_FIRMWARE_UPDATE: '/gvms/api/v2/sync/firmware-update',
    VW_MAPS_UPDATE: '/vw/api/v1/download/url',
    VEHICLE_TYPE: '/fs/api/v3/vehicles/vehicle-type',
    AGGREGATE_POINTS: '/fs/api/v3/loyalty/aggregatedPoints',
    CONNECTED_VEHICLE: '/owner/api/v3/vehicle/status',
    TERMS_AND_CONDITIONS: '/climes/api/v2/terms',
    TERMS_AND_CONDITIONS_CONSENT: '/owner/api/v2/consents',
    MMOTA: '/mmota/api/v1/details',
    VHA: '/fs/api/v2/vhr/vha',
    SERVICE_HISTORY_AUTH: '/fs/api/v3/service-history',
    SERVICE_HISTORY_UNAUTH: '/fs/api/v3/service-history/unauth',
    SERVICE_HISTORY_PROVIDERS: '/fs/api/v3/service/providers',
    ADD_SERVICE_HISTORY: '/owner/api/v3/global/service-history',
    DELETE_SERVICE_HISTORY: '/fs/api/v3/service/history',
    SERVICE_TYPES: '/fs/api/v3/service/types',
    LEGAL_TERMS: '/climes/api/v2/terms/service-history',
    WARRANTY_INFORMATION_UNAUTH: '/owner/api/v3/vehicle/warranty/unauth',
    WARRANTY_INFORMATION_AUTH: '/owner/api/v3/vehicle/warranty',
    VHR_LANDING: '/fs/api/v3/vhr/landing',
    VHR_REPORT: '/fs/api/v3/vhr/report',
    VHR_SCHEDULE: '/fs/api/v2/vhr/schedule',
    VEHICLE_STATUS: '/owner/api/v3/vehicle/order-status',
    ONBOARDING_NEW_AVAILABLE_DATES: '/owner/api/v2/availability',
    ONBOARDING_SCHEDULE_APPOINTMENT: '/owner/api/v2/scheduling/appointment',
    ONBOARDING_CANCEL_APPOINTMENT: '/owner/api/v2/scheduling/appointment',
    ONBOARDING_GET_APPOINTMENT_DETAILS: '/owner/api/v2/booking-details',
    ONBOARDING_CONFIRM_APPOINTMENT: '/owner/api/v2/confirm-booking',
    ONBOARDING_UPDATE_APPOINTMENT_AUTH: '/owner/api/v2/update-booking',
    ONBOARDING_UPDATE_APPOINTMENT_UNAUTH: '/owner/api/v2/update-booking/unauth',
    ONBOARDING_EDIT_APPOINTMENT: '/owner/api/v2/edit-appointment',
    ONBOARDING_DELETE_APPOINTMENT: '/owner/api/v2/delete-booking',
    FEATURE_GUIDE_METRICS: '/featureguide/api/v3/customer/metrics',
    MAINTENANCE_SCHEDULE: '/fs/api/v2/vehicles/maintenance/schedule',
    COUPONS_API_CALL: '/gtb/api/v2/coupons',
    CONSUMER_ID: '/owner/api/v2/consumer-id',
    AMI_CHECK_STATUS: '/ami/api/v3/status-request',
    EMAIL_FORGOT_TRACKING_NUMBER: '/email/api/v1/email-tracking-info',
    VIN_VALIDATION: '/wsmod/api/v3/decode-vin',
    DEALER_LIST: '/bing/api/v3/dealers',
    REBATE_OFFERS: '/ami/api/v4/offers',
    POPULAR_VIDEOS: '/brightcove/api/v2/popular-videos',
    POPULAR_VIDEOS_BY_VIEW_COUNT:
        '/brightcove/api/v2/popular-videos-by-view-count',
    POPULAR_VIDEOS_BY_YMM: '/brightcove/api/v2/popular-videos-by-ymm',
    VIDEOS_BY_CATEGORIES: '/brightcove/api/v2/videos-by-categories',
    VIDEOS_BY_CATEGORY: '/brightcove/api/v2/videos-by-category',
    VIN_ORCHESTRATION: '/vehicle-data/api/v3/vehicle',
    VEHICLE_DATA_VIN: '/vehicle-data/api/v4/vehicle',
    VEHICLE_DATA_YMM: '/vehicle-data/api/v2/ymm/vehicle',
    VEHICLE_DATA_TRIMS_AND_SPECS: '/vehicle-data/api/v2/ymm/vehicle/trims',
    GET_BOOKMARKS: '/owner/api/v3/bookmark/get-bookmark',
    ADD_BOOKMARK: '/owner/api/v3/bookmark/add-bookmark',
    DELETE_BOOKMARK: '/owner/api/v3/bookmark/delete-bookmark',
    VEHICLE_PROGNOSTICS: '/fs/api/v2/vehicles/prognostics',
    VEHICLE_PROGNOSTICS_V2: '/fs/api/v3/vehicles/prognostics',
    OWNERS_MANUAL_VIN_V3: '/pts/api/v3/owner-information-vin',
    GPRS_VEHICLE_DATA: '/vw/api/v2/gprs-vehicle-data',
    OWNER_MANUALS: '/pts/api/v2/owner-information-model-year',
    UPDATE_USER_INFO: '/fs/api/v3/profile',
    OSB_IMAGE_UPLAOD: '/osb/api/public/v5',
    SYNC_UPLOAD_LOG_FILES: '/gvms/api/v1/upload-async',
    OSB_DEALER_SERVICES:
        '/osb/api/public/v1/:locale/dealers/:dealercode/services',
    OSB_DEALER_SERVICESV5:
        '/osb/api/public/v5/:locale/dealers/:dealerCode/services',
    OSB_VEHICLE_LOOKUP: '/osb/api/public/v1/:locale/vehicles',
    OSB_VEHICLE_LOOKUP_POST:
        '/osb/api/public/v4/:locale/dealers/:dealerCode/vehicles',
    OSB_CALENDAR_AVAILABILITY:
        '/osb/api/public/v1/:locale/dealers/:dealercode/availability',
    OSB_CALENDAR_AVAILABILITYV5:
        '/osb/api/public/v5/:locale/dealers/:dealerCode/availability',
    UTE_RETRIEVE_PROFILE: '/fs/api/v2/ute/v1/profile',
    UTE_POST_SERVICES: '/fs/api/v2/ute/v1/profile',
    UTE_PROFILE_SEARCH: '/fs/api/v2/ute/v1/profile/trailer-template',
    DSL_OSB_CREATE_BOOKING:
        '/osb/api/public/v1/:locale/dealers/:dealerCode/bookings',
    DSL_OSB_CREATE_BOOKINGV5:
        '/osb/api/public/v5/:locale/dealers/:dealerCode/bookings',
    DSL_OSB_RETRIEVE_BOOKING: '/osb/api/public/v1/:locale/bookings',
    DSL_OSB_RETRIEVE_BOOKINGV5: '/osb/api/public/v5/:locale/bookings',
    DSL_OSB_CANCEL_BOOKING:
        '/osb/api/public/v5/:locale/dealers/:dealerId/bookings/:bookingId',
    DSL_OSB_AMEND_BOOKING:
        '/osb/api/public/v5/:locale/dealers/:dealerCode/bookings/:bookingId',
    ORDER_STATUS: '/fs/api/v2/reservations',
    OSB_DEALER_DETAILS: '/osb/api/public/v1/:locale/dealers',
    TC_USER_PREFERENCES: '/fs/api/v2/profile/unit',
    VEHICLE_PROFILE: '/fs/api/v2/ota/vehicle-profile',
    OSB_RETRIEVE_ACCESS_CODE:
        '/osb/api/public/v5/:locale/bookings/retrieveAccessCodes',
    IMG_WARRANTY_DETAILS: '/fs/api/v3/vehicles/img-warranty-and-esb',
    CONSUMER_YFA: '/consumer/api/cat/v1/yfa',
    YFA_PRIVACY_PREFERENCES: '/owner/api/v2/yfa-privacy',
    YFA_ELIGIBILITY: '/consumer/api/cat/v2/yfa/eligibility',
    YFA_ENROLL_WITH_VIN: '/consumer/api/cat/v1/yfa/enroll/vin',
    LINCOLN_CALM: '/consumer/api/cat/v1/calm',
    SUBSCRIPTIONS: '/consumer/api/cat/v2/subscriptions',
    EXTENDED_SERVICE_BUSINESS: '/fs/api/v3/vehicles/extended-service-business',
    SYNC_HAS_JIRA_TICKET_OPEN: '/jira/api/v2/public/getTicketStatus',
    QUICKLANE_OFFERS: '/ql/api/v3/offers',
    TRADE_IN_LINK: '/gcamp/api/v4/tradein/referral',
    AI_SEARCH: '/pts/api/v2/ai-search',
    AI_SEARCH_FEEDBACK: '/pts/api/v2/ai-search/feedback',
    RETRIEVE_ARTICLE: '/pts/api/v3/article',
    RETRIEVE_ARTICLES: '/pts/api/v3/articles',
    HISTORICAL_RELEASE_NOTES: '/gvms/api/v1/releasenotes',
};

export const SESSION_KEY_API_CACHE = 'apiCache';

export const KEYBOARD_KEYS = {
    ESC: 'Escape',
    TAB: 'Tab',
    ENTER: 'Enter',
    END: 'End',
    HOME: 'Home',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_UP: 'ArrowUp',
    ARROW_DOWN: 'ArrowDown',
    SPACE: ' ',
};

export const BRAND = {
    ford: {
        LONG_NAME: 'ford',
        SHORT_NAME: 'F',
    },
    lincoln: {
        LONG_NAME: 'lincoln',
        SHORT_NAME: 'L',
    },
    lincolndemo: {
        LONG_NAME: 'lincolndemo',
        SHORT_NAME: 'L',
    },
    demo: {
        LONG_NAME: 'ford',
        SHORT_NAME: 'F',
    },
};

export const COLORS = {
    FM_GREEN_1000: '#008200',
    FM_RED_1000: '#BF152C',
    FM_WHITE: '#ffffff',
    WARNING: '#DA7029',
    ERROR: '#D62D0A',
    DEFAULT: '#00095B',
};

export const GDPR_COOKIE = 'guxfoe-cookiedisclaimer';
export const COOKIE_CONFIGURATION = 'cookie-configuration';
export const SYNDICATED_HEADER_COUNTRIES = [
    'us',
    'ca',
    'br',
    'mx',
    'ar',
    'cl',
    'co',
    'au',
    'nz',
    'th',
    'pe',
    've',
    'za',
    'pr',
    'vn',
    'ph',
    'ag',
    'aw',
    'bs',
    'bb',
    'bm',
    'ky',
    'cw',
    'dm',
    'do',
    'gd',
    'ht',
    'jm',
    'lc',
    'mf',
    'sr',
    'tt',
    'bz',
    'cr',
    'sv',
    'gt',
    'hn',
    'ni',
    'pa',
];

export const SYNDICATED_IMG_HEADER_COUNTRIES = [
    'br',
    'mx',
    'ar',
    'cl',
    'co',
    'au',
    'nz',
    'th',
    'pe',
    've',
    'za',
    'pr',
    'vn',
    'ph',
    'ag',
    'aw',
    'bs',
    'bb',
    'bm',
    'ky',
    'cw',
    'dm',
    'do',
    'gd',
    'ht',
    'jm',
    'lc',
    'mf',
    'sr',
    'tt',
    'bz',
    'cr',
    'sv',
    'gt',
    'hn',
    'ni',
    'pa',
];

export const SYNDICATED_IMG_LATAM_LINCOLN_COUNTRIES = ['pr', 'mx'];

export const EU_COUNTRIES = [
    'gb',
    'ch',
    'de',
    'fr',
    'it',
    'es',
    'no',
    'nl',
    'fi',
    'mothersite',
    'pl',
    'pt',
    'ie',
    'dk',
    'hu',
    'ro',
    'be',
    'lu',
    'at',
    'gr',
    'cz',
    'se',
];
export const GA_ZERO_CAR_SCENARIO_COUNTRIES = [
    'us',
    'ca',
    'au',
    'nz',
    'th',
    'za',
    'ph',
    'gb',
    'de',
    'fr',
    'es',
    'it',
    'pl',
    'gr',
    'lu',
    'ie',
    'hu',
    'no',
    'nl',
    'ch',
    'cz',
    'fi',
    'be',
    'at',
    'ro',
    'dk',
    'pt',
    'ar',
    'br',
    'cl',
    'co',
    'pe',
    've',
    'vn',
];

export const GA_COUNTRIES_BRAND_SPECIFIC = ['ford_es-mx'];
export const SYNDICATED_CARIBE_HEADER_COUNTRIES = [
    'ag',
    'aw',
    'bs',
    'bb',
    'bm',
    'ky',
    'cw',
    'dm',
    'do',
    'gd',
    'ht',
    'jm',
    'lc',
    'mf',
    'sr',
    'tt',
];
export const SYNDICATED_CA_HEADER_COUNTRIES = [
    'bz',
    'cr',
    'sv',
    'gt',
    'hn',
    'ni',
    'pa',
];

export const BRAND_SITE_COUNTRIES_WITH_REDIRECT_ISSUE = [
    'ph',
    'vn',
    'br',
    'pe',
];
export const SYNDICATE_UTIL_COUNTRIES = [
    'au',
    'nz',
    'th',
    'za',
    'ph',
    'vn',
    'pr',
    'br',
    'pe',
    'ca',
];
export const COUNTRIES_WITH_WARRANTY_DATE = ['us', 'ca', 'za'];
export const IMG_COUNTRIES_WITH_WARRANTY_STATUS = ['th'];

export const NEW_VEHICLE_DASHBOARD_COUNTRIES = ['ca'];

export const BRIGHTCOVE_API_PATH = {
    BRIGHTCOVE_BASE_URL: 'https://edge.api.brightcove.com/playback/v1/',
};

export const BRIGHTCOVE = {
    ford: {
        ACCOUNT_ID: '2379864788001',
        PLAYER_ID: 'B1eFuwPXkG',
    },
    lincoln: {
        ACCOUNT_ID: '2379864788001',
        PLAYER_ID: 'H1T3fN51G',
    },
};

export const IS_MANUAL_FLOW_BY_DEFAULT = false;

export const CSP_SCRIPT_SOURCES = [
    '*.jquery.com',
    '*.googletagmanager.com',
    '*.gstatic.com',
    '*.google-analytics.com',
    '*.adobedtm.com',
    '*.evidon.com',
    '*.cookielaw.org',
    '*.liveperson.net',
    '*.liveperson.com',
    '*.lpsnmedia.net',
    '*.iperceptions.com',
    '*.onetrust.com',
    '*.mpeasylink.com',
    '*.go-mpulse.net',
    '*.mouseflow.com',
    '*.amazonaws.com',
    '*.amitirefinder.com',
    '*.brightcove.net',
    '*.zencdn.net',
    '*.omtrdc.net',
    '*.youtube.com',
    '*.virtualearth.net',
    '*.googleapis.com',
    '*.marketingassociates.com',
    '*.bing.com',
    '*.facebook.net',
    '*.kampyle.com',
    '*.medallia.com',
    '*.googleadservices.com',
    '*.adnxs.com',
    '*.pinimg.com',
    '*.googlesyndication.com',
    '*.convertlanguage.com',
    '*.contentsquare.net',
    '*.hotjar.com',
    '*.stripe.com',
    '*.evergage.com',
    '*.evgnet.com',
    '*.sophus3.com',
    '*.windows.net',
    '*.azureedge.net',
    '*.doubleclick.net',
    '*.knowbl.com',
    '*.everestjs.net',
    '*.s3chat.com',
];

export const CSP_DOMAINS = [
    '*.lincoln.mx',
    '*.lincoln.com.pr',
    '*.ford.com',
    '*.lincoln.com',
    '*.ford.ca',
    '*.lincolncanada.com',
    '*.ford.co.uk',
    '*.ford.ch',
    '*.ford.de',
    '*.ford.fr',
    '*.ford.it',
    '*.ford.es',
    '*.ford.no',
    '*.ford.nl',
    '*.ford.fi',
    '*.ford.com.br',
    '*.ford.mx',
    '*.ford.com.ar',
    '*.ford.pl',
    '*.ford.pt',
    '*.ford.ie',
    '*.ford.dk',
    '*.ford.hu',
    '*.ford.ro',
    '*.ford.be',
    '*.ford.lu',
    '*.ford.at',
    '*.ford.gr',
    '*.ford.cz',
    '*.ford.com.au',
    '*.ford.co.nz',
    '*.ford.com.co',
    '*.ford.co.th',
    '*.ford.cl',
    '*.ford.pe',
    '*.ford.com.ve',
    '*.ford.co.za',
    '*.ford.com.pr',
    '*.ford.com.vn',
    '*.centroamerica.ford.com',
    '*.caribe.ford.com',
    '*.ford.com.ph',
];

export const AEM_DAM_PATH = '/content/dam/global-owner';

export const REGIONAL_LOCALES = Array.from(
    new Set(
        appConfigurationProd.countryConfigurations
            .flatMap(
                countryConfiguration =>
                    countryConfiguration.regionConfigurations
            )
            .map(config => config.languageRegionCode)
    )
);

export const BEV_SEO_KEY_CANDIDATES = ['mach-e', 'lightning'];

export const USER_GREETING_DELAY_SECONDS = 3600;
export const USE_CHECKSHEET_API = true;

export const CONNECTED_VEHICLE_CARD_TYPE = {
    FUEL_LEVEL: 'fuel-level',
    BEV_CHARGE_LEVEL: 'bev-charge-level',
    CURRENT_MILEAGE: 'current-mileage',
    TIRE_PRESSURE: 'tire-pressure',
    OIL_LIFE: 'oil-life',
};
export const QUICK_LINKS_CARD_TYPE = {
    WIFI: 'wifi-hotspot',
    CITT: 'can-i-tow-this',
    OWNERS_MANUAL: 'owner-manual',
    SCHEDULE_SERVICE: 'schedule-service',
};
export const CONNECTED_VEHICLE_CARD_TYPE_V2 = {
    FUEL_LEVEL: 'fuelLevel',
    BEV_CHARGE_LEVEL: 'chargeLevel',
    CURRENT_MILEAGE: 'currentMileage',
    TIRE_PRESSURE: 'tirePressure',
    OIL_LIFE: 'oilLife',
    BATTERY_RANGE: 'batteryRange',
};
export const CONNECTED_VEHICLE_CARD_TYPE_V3 = {
    CURRENT_MILEAGE: 'currentMileage',
    TIRE_PRESSURE: 'tirePressure',
    BEV_CHARGE_LEVEL: 'chargeLevel',
    OIL_LIFE: 'oilLife',
    FUEL_LEVEL: 'fuelLevel',
    BATTERY_RANGE: 'batteryRange',
};

export const ELIGIBLE_MODEL_NAMES = ['mustang mach-e', 'f-150 lightning'];

export const FM_ACTION_ITEMS_TYPE = {
    RECALLS_AND_CSP: 'recalls-and-csp',
    LOGIN: 'login',
    YFA: 'your-ford-ambassador',
    SOFTWARE_UPDATE: 'software-updates',
    SYNC_MAP: 'sync-map',
    SYNC: 'sync',
    VHA: 'vehicle-health-alert',
    SERVICE_REMINDER: 'service-reminder',
};
export const VEHICLE_CHECKLIST_CARD_TYPE = {
    OTA: 'ota-updates',
    SYNC: 'software-updates',
    VHR: 'vehicle-health-reports',
    DYF: 'new-vehicle-orientation',
    RESERVATION_ORDER_STATUS: 'reservation-order-status',
    RECALLS: 'recalls',
    TAKATA_RECALLS: 'takata-recalls',
    WARRANTY: 'warranty-information',
    FPR: 'ford-pass-rewards',
    SYNC_MAPS: 'your-maps',
    SOFTWARE_UPDATE_MAPS: 'software-update-maps',
    CSP: 'csp',
    ESB: 'extended-service-business',
    VEHICLE_HEALTH: 'vehicle-health',
    YFA: 'your-ford-ambassador',
    SUBSCRIPTIONS: 'subscriptions',
};

export const DYF_ARTICLE_CATEGORY_NAMES = {
    ELECTRIC_VEHICLES_DISPLAY: 'Electric Vehicles',
    ELECTRIC_VEHICLES_QUERY: 'electric-vehicles',
    ELECTRIC_VEHICLES_DISPLAY_FR: 'Véhicules Électriques',
    ELECTRIC_VEHICLES_QUERY_FR: 'vehicules-electriques',
    FORD_TECHNOLOGY_DISPLAY: 'Ford Technology',
    FORD_TECHNOLOGY_QUERY: 'ford-technology',
    FORD_TECHNOLOGY_DISPLAY_FR: 'Technologie Ford',
    FORD_TECHNOLOGY_QUERY_FR: 'technologie-ford',
    SYNC_DISPLAY: 'SYNC',
    SYNC_QUERY: 'sync',
};

export const DYF_ELIGIBLE_YEARS: number[] = [
    2020,
    2021,
    2022,
    2023,
    2024,
    2025,
];
export const DYF_SIGNIN_REGEX_ENG = new RegExp('sign in', 'i');
export const DYF_SIGNIN_REGEX_FR = new RegExp('connectez-vous', 'i');

export const HTV_VIDEO_NAME_REGEX = new RegExp(/[^a-zA-Zà-žÀ-Ž0-9- ]/g);

export const SOPHUS_CHAT_COUNTRIES: { [keys: string]: string } = {
    default: 'https://fordlc-qa.s3chat.com/deploy/services.min.js',
    gb: 'https://fordlc-dealer-uk.s3chat.com/deploy/services.min.js',
    nl: 'https://fordlc-nl.s3chat.com/deploy/services.min.js',
};
export const PC_DEFAULT_DEALER_CODE = '11111';

type RouteParam =
    | 'vehicleName'
    | 'year'
    | 'categoryName'
    | 'numbers'
    | 'vinGeneric'
    | 'videoIdOrName';

export const ROUTE_REGEX: Record<RouteParam, string> = {
    vehicleName: '[a-zA-Z0-9-]+',
    year: '[0-9]{4}',
    categoryName: '[a-zà-ÿ-]+',
    numbers: '[0-9]+',
    vinGeneric: '[A-Za-z0-9]{17}',
    videoIdOrName: '[a-zà-ÿ0-9-]+',
};
export const SELECTORS = {
    NA_LANG_LINK_SELECTOR: '.language-link',
};
